import axios from "axios";
import {Service} from "axios-middleware";
import * as CONFIG from "../Config";

const service = new Service(axios);

service.register({
    onRequest(config) {
        config.headers.Authorization = `Bearer ${CONFIG.AuthConfig.token}`;
        if (!config.data) {
            config.data = {};
        }
        return config;
    }
});

export default axios;
