import {System} from "./interfaces/System";

export class SystemListModel {
    values: System[] = [];

    /**
     * @summary コンストラクタ
     */
    public constructor(systems: Partial<System[]> = []) {
        Object.assign(this.values, systems);
    }

    public getBy(systemId: number): System {
        for (const value of this.values) {
            if (value.id === systemId) return value;
        }

        return {id: 0, name: "", showOrder: 0, systemColor: ""};
    }

    public existsBy(systemId: number): boolean {
        return this.getBy(systemId).id !== 0;
    }
}
