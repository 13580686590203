import { TaskSearchParamModel } from "./models/TaskSearchParamModel";
import moment from "moment";
import { IssueSearchParamModel } from "./models/IssueSearchParamModel";
import { UpdateLogSearchParamModel } from "./models/UpdateLogSearchParamModel";
import { ResponseLogSearchParamModel } from "./models/ResponseLogSearchParamModel";
import { SearchDailyReportRequestModel } from "./models/SearchDailyReportRequestModel";
import { AddDailyReportContentRequest } from "./models/interfaces/DailyReport/AddDailyReportContentRequest";
import { EditDailyReportContentRequest } from "./models/interfaces/DailyReport/EditDailyReportContentRequest";

export namespace AuthConfig {
    export let token = (() => {
        const token = localStorage.getItem("token");
        return token || "";
    })();

    export let refreshToken = (() => {
        const refreshToken = localStorage.getItem("refreshToken");
        return refreshToken || "";
    })();

    export let loginId = (() => {
        const id = localStorage.getItem("loginId");
        return id || "";
    })();

    export let expiresIn = (() => {
        const expiresIn = localStorage.getItem("expiresIn");
        return expiresIn ? new Date(expiresIn) : new Date();
    })();

    export let memberName = (() => {
        const name = localStorage.getItem("memberName");
        return name || "";
    })();

    export let memberId = (() => {
        const id = localStorage.getItem("memberId");
        return id ? Number(id) : 0;
    })();

    export let isLogin = (() => {
        return !!localStorage.getItem("isLogin");
    })();

    export let groupIds = (() => {
        try {
            const json = localStorage.getItem("groupIds");
            if (json) {
                const ids = JSON.parse(json) as number[];
                return ids;
            }
        }
        catch {
            console.log("error");
        }

        return [];
    })();
}

export let URL = (() => {
    let origin = location.protocol + "//" + location.hostname;
    if (location.port.length > 0 && location.port === "8080") {
        const port = Number(location.port);
        origin += ":" + port;
    }
    return origin;
})();

export const DefaultTaskSearchParam = () => {
    return new TaskSearchParamModel(
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 97, 98, 999]
    );
};

export const DefaultGanttChartSearchParam = () => {
    return new TaskSearchParamModel(
        [1, 2, 7, 8]
    );
};

export const DefaultResponseLogSearchParam = () => {
    return new ResponseLogSearchParamModel();
};

export const DefaultIssueSearchParam = () => {
    return new IssueSearchParamModel();
};

export const DefaultUpdateLogSearchParam = () => {
    return new UpdateLogSearchParamModel([]);
};

export const DefaultDailyReportSearchParam = (sheetId: number, personIdArr: number[] = [], targetYearMonth: string = moment().format("YYYY-MM")) => {
    return new SearchDailyReportRequestModel(
        sheetId,
        1,
        personIdArr,
        targetYearMonth
    );
};

export const DailyReportMultiInputMax = () => {
    return 10;
};

export const DefaultDailyReportMultiInputParams = (): AddDailyReportContentRequest[] => {
    const ret: AddDailyReportContentRequest[] = [];
    let count = DailyReportMultiInputMax();
    while (count !== 0) {
        ret.push({ systemId: 0, workTypeId: 0, workHours: 1, description: "" });
        count--;
    }

    return ret;
};

export const DefaultDailyReportMultiInputParamsForEdit = (): EditDailyReportContentRequest[] => {
    const ret: EditDailyReportContentRequest[] = [];
    let count = DailyReportMultiInputMax();
    while (count !== 0) {
        ret.push({ systemId: 0, workTypeId: 0, workHours: 1, description: "" });
        count--;
    }

    return ret;
};

export let DEFAULT_SHEET_ID = 1;
