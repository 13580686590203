import Vue from "vue";
import Vuex from "vuex";
import {TaskStore} from "@/stores/TaskStore";
import {UpdateLogStore} from "@/stores/UpdateLogStore";
import {SheetStore} from "@/stores/SheetStore";
import {NewThreadStore} from "@/stores/NewThreadStore";
import {TaskThreadStore} from "@/stores/TaskThreadStore";
import {IssueStore} from "@/stores/IssueStore";
import {IssueThreadStore} from "@/stores/IssueThreadStore";
import {ResponseLogStore} from "@/stores/ResponseLogStore";
import {ResponseLogThreadStore} from "@/stores/ResponseLogThreadStore";
import {AuthStore} from "@/stores/AuthStore";
import {MasterInfoStore} from "@/stores/MasterInfoStore";

Vue.use(Vuex);

export interface RootState
{
  task: TaskStore;
  updateLog: UpdateLogStore;
  sheet: SheetStore;
  newThread: NewThreadStore;
  taskThread: TaskThreadStore;
  issue: IssueStore;
  issueThread: IssueThreadStore;
  responseLog: ResponseLogStore;
  responseLogThread: ResponseLogThreadStore;
  auth: AuthStore;
  masterInfo: MasterInfoStore;
}

export default new Vuex.Store<RootState>({
});
