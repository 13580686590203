import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export const routes = [
    {
        path: "/",
        name: "dash-board",
        component: () => import("./components/Views/DashBoardPage.vue"),
        title: "トップ",
        icon: "mdi-home"
    },
    {
        path: "/task",
        name: "task-list",
        component: () => import("./components/Views/TaskListPage.vue"),
        title: "タスク一覧",
        icon: "mdi-playlist-check"
    },
    {
        path: "/update-log",
        name: "update-log",
        title: "アップデート履歴",
        icon: "mdi-chart-gantt",
        component: () => import("./components/Views/UpdateLogPage.vue")
    },
    {
        path: "/issue",
        name: "issue",
        title: "エラー報告",
        icon: "mdi-alert-circle",
        component: () => import("./components/Views/IssuePage.vue")
    },
    {
        path: "/response-log",
        name: "response-log",
        title: "対応ログ",
        icon: "mdi-history",
        component: () => import("./components/Views/ResponseLogPage.vue")
    },
    {
        path: "/daily-report",
        name: "daily-report",
        title: "日報",
        icon: "mdi-file-document-edit-outline",
        component: () => import("./components/Views/DailyReportPage.vue")
    }
];

/**
 * @summary ナビゲーションに表示させないルート設定
 */
const notNavigationRouteList = [
    {
        path: "/gantt-chart",
        name: "gantt-chart",
        title: "ガントチャート",
        component: () => import("./components/Views/GanttChartPage.vue")
    },
    {
        path: "/daily-report-summary",
        name: "daily-report-summary",
        title: "日報集計",
        component: () => import("./components/Views/DailyReportPage.vue")
    }
];

export const routesDictionary = (() => {
    const dic: { [key: string]: {} } = {};
    for (const item of routes) {
        dic[item.name] = item;
    }

    for (const item of notNavigationRouteList) {
        dic[item.name] = item;
    }
    return dic;
})();

/**
 * @summary ページのルーティングを提供します.
 */
export default new Router({
    mode: "history",
    base: "/",
    routes: [
        ...routes,
        ...notNavigationRouteList
    ]
});
