
















































import {Component, Vue} from "vue-property-decorator";
import {AuthStore, AuthStoreModule} from "@/stores/AuthStore";
import router from "@/router";

import {AuthConfig, URL} from "@/Config";

@Component({
    components: {
    }
})
export default class LoginPage extends Vue {
    // region private fields
    private loginId = "";
    private password = "";
    private isLoading = false;
    private isLoginError = false;
    private emailRules = [
        (v: string) => !!v || "E-mail is required",
        (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ];
    // #endregion

    // #region properties
    private get AuthStore(): AuthStore {
        return AuthStoreModule;
    }
    // #endregion

    private async login(): Promise<void> {
        this.isLoginError = false;
        this.isLoading = true;
        const isSuccess = await this.AuthStore.login({loginId: this.loginId, password: this.password});
        this.isLoading = false;
        if (isSuccess) {
            location.href = "/";
            location.reload();
            return;
        }

        this.isLoginError = true;
    }
}
