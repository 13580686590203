import {SearchDailyReportRequest} from "./interfaces/DailyReport/SearchDailyReportRequest";

/**
 * @summary タスク検索で使用する検索パラメータを提供します
 */
export class SearchDailyReportRequestModel implements SearchDailyReportRequest {
    sheetId: number;
    personIdArr?: number[];
    searchType: number;
    targetYearMonth?: string;
    freeWord?: string;

    /**
     * @summary コンストラクタ
     */
    public constructor(sheetId: number, searchType: number, personIdArr?: number[], targetYearMonth?: string, freeWord?: string) {
        this.sheetId = sheetId;
        this.searchType = searchType;
        this.personIdArr = personIdArr;
        this.targetYearMonth = targetYearMonth;
        this.freeWord = freeWord;
    }

    public getQueryString(): string {
        const query: string[] = [];

        if (this.sheetId) {
            query.push(`sheetId=${this.sheetId}`);
        }

        if (this.searchType) {
            query.push(`searchType=${this.searchType}`);
        }

        if (this.personIdArr && this.personIdArr.length > 0) {
            for (const personId of this.personIdArr) {
                query.push("personIdArr[]=" + personId);
            }
        }

        if (this.targetYearMonth) {
            query.push("targetYearMonth=" + this.targetYearMonth);
        }

        if (this.freeWord) {
            query.push("freeWord=" + this.freeWord);
        }

        return "?" + query.join("&");
    }
}
