























































































































import {Component, Vue} from "vue-property-decorator";
import Navigator from "./components/Templates/Navigator.vue";
import {MasterInfoStore, MasterInfoStoreModule} from "./stores/MasterInfoStore";
import {AuthStoreModule, AuthStore} from "./stores/AuthStore";
import router, {routesDictionary} from "./router";
import LoginPage from "@/components/Views/LoginPage.vue";
import {AuthConfig} from "@/Config";
import {SheetStoreModule, SheetStore} from "@/stores/SheetStore";
@Component({
    components: {
        Navigator,
        LoginPage
    }
})
export default class App extends Vue {
    // #region private properties
    private isOpened = false; // localStorage.getItem("drawer") === "true"
    private isAutheticated = false;
    private isLoading = false;
    private isLoginChecked = false;
    private routes = routesDictionary;
    // #endregion

    // #region private properties
    /**
     * @summary 認証ストア
     */
    private get authStore(): AuthStore {
        return AuthStoreModule;
    }

    /**
     * @summary 現在選択中のシートID
     */
    private get currentSheetId(): number {
        return this.sheetStore.currentSheetId ? this.sheetStore.currentSheetId : 0;
    }
    private set currentSheetId(id: number) {
        this.sheetStore.setCurrentSheetId(id);
        location.reload();
    }

    /**
     * @summary ダークテーマかどうか
     * @description データはローカルストレージに保存しています. プロパティにアクセスした際にvuetifyのテーマに適用します.
     */
    private get isDark(): boolean {
        const isDark = localStorage.getItem("isDark") === "true";
        this.$vuetify.theme.dark = isDark;
        return isDark;
    }
    private set isDark(isDark: boolean) {
        this.$vuetify.theme.dark = isDark;
        localStorage.setItem("isDark", isDark ? "true" : "false");
    }

    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    private get sheetStore(): SheetStore {
        return SheetStoreModule;
    }
    // #endregion

    // #region private methods
    private async created(): Promise<void> {
        this.isLoading = true;

        this.isAutheticated = await this.checkLoginState();
        this.isLoginChecked = true;
        if (!this.isAutheticated) {
            this.isLoading = false;
            // ストレージデータのクリア
            this.authStore.logout();
            return;
        }

        await this.fetchInitialData();
        this.isLoading = false;
    }

    private mounted(): void {
    }

    private async fetchInitialData(): Promise<void> {
        await this.sheetStore.fetchSheetList();
        if (this.currentSheetId === undefined) {
            if (this.sheetStore.sheets.length > 0) {
                this.currentSheetId = this.sheetStore.sheets[0].id;
            }
        }

        const tasks: Promise<void>[] = [];
        tasks.push(this.masterInfoStore.fetchPersons());
        tasks.push(this.masterInfoStore.fetchSystemsMaster(this.currentSheetId));
        await this.masterInfoStore.fetchStatusesMaster(this.currentSheetId);
        await Promise.all(tasks);
    }

    /**
     * @summary ナビゲーションの開閉
     * @description データはローカルストレージに保存しています. プロパティにアクセスした際にvuetifyのテーマに適用します.
     * @param isOpened 開けるか占めるか
     */
    private switchDrawer(): void {
        this.isOpened = !this.isOpened;
        localStorage.setItem("drawer", this.isOpened ? "true" : "false");
    }

    /**
     * @summary ログアウトします.
     */
    private logout(): void {
        this.authStore.logout();
        location.href = "/";
        location.reload();
    }

    /**
     * ログイン状態が有効かどうかチェックします.
     */
    private async checkLoginState(): Promise<boolean> {
        const isAuthenticated = await this.authStore.getIsAuthenticated();
        if (isAuthenticated) {
            return await this.authStore.validateToken();
        }

        // リフレッシュトークンを試みる
        const isSuccessRefresh = await this.authStore.tryRefreshToken();
        if (isSuccessRefresh) {
            return await this.authStore.validateToken();
        }
        return false;
    }
    // #endregion
}
