import {IssueSearchParam} from "./interfaces/IssueSearchParam";

/**
 * @summary タスク検索で使用する検索パラメータを提供します
 */
export class IssueSearchParamModel implements IssueSearchParam {
    public statusIdArr?: number[] = undefined;
    public createDateArr?: string[] = [];
    public requestDetail?: string = undefined;
    public sheetId?: number = 0;

    /**
     * @summary コンストラクタ
     */
    public constructor(statusIdArr?: number[], createDateArr?: string[], requestDetail?: string) {
        this.statusIdArr = statusIdArr;
        this.createDateArr = createDateArr;
        this.requestDetail = requestDetail;
    }

    public getQueryString(): string {
        const query: string[] = [];

        if (this.statusIdArr && this.statusIdArr.length > 0) {
            for (const statusId of this.statusIdArr) {
                query.push("statusList[]=" + statusId);
            }
        }

        if (this.createDateArr && this.createDateArr[0]) {
            query.push("fromCreateDate=" + this.createDateArr[0]);
        }

        if (this.createDateArr && this.createDateArr[1]) {
            query.push("endCreateDate=" + this.createDateArr[1]);
        }

        if (this.requestDetail) {
            query.push("requestDetail=" + this.requestDetail);
        }

        if (this.sheetId) {
            query.push("sheetId=" + this.sheetId);
        }

        return "?" + query.join("&");
    }
}
