import axios from "axios";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";
import {IdAndName} from "@/models/interfaces/IdAndName";
import {Status} from "@/models/interfaces/Status";
import {StatusType} from "@/models/StatusType";
import {StyleGenerator} from "@/core/StyleGenerator";
import {System} from "@/models/interfaces/System";
import {MWorkType} from "@/models/interfaces/MWorkType";
import {SystemListModel} from "@/models/SystemListModel";
import {MWorkTypeListModel} from "@/models/MWorkTypeListModel";

/**
 * @summary タスク情報に関するStoreを提供します.
 */
@Module({dynamic: true, store, name: "masterInfo", namespaced: true})
export class MasterInfoStore extends VuexModule {
    // #region private fields
    private _systemsMaster: SystemListModel = new SystemListModel();
    private _mPersons: {[key: number]: IdAndName} = {};
    private _taskStatusesMaster: {[key: number]: IdAndName} = {};
    private _responseLogStatusesMaster: {[key: number]: IdAndName} = {};
    private _issueStatusesMaster: {[key: number]: IdAndName} = {};
    private _workTypeMasterList: MWorkTypeListModel = new MWorkTypeListModel();
    // #endregion

    // #region public getters
    /**
     * @summary タスクシステムマスター情報
     */
    public get systemsMaster(): SystemListModel {
        return this._systemsMaster;
    }

    public get systemNameById(): (_: number) => string | null {
        return id => this._systemsMaster.getBy(id).name;
    }

    /**
     * @summary タスクステータスマスター情報
     */
    public get taskStatusesMaster(): {[key: number]: IdAndName} {
        return this._taskStatusesMaster;
    }

    /**
    * @summary タスク優先度マスター情報
    */
    public get taskPrioritiesMaster(): {[key: number]: IdAndName} {
        return {
            1: {id: 1, name: "1"},
            2: {id: 2, name: "2"},
            3: {id: 3, name: "3"},
            4: {id: 4, name: "4"},
            5: {id: 5, name: "5"}
        };
    }

    /**
     * @summary タスク担当者マスター情報
     */
    public get personsMaster(): {[key: number]: IdAndName} {
        return this._mPersons;
    }

    public get parsonNameById(): (id: number) => string | null {
        return id => this._mPersons[id]?.name;
    }

    /**
     * @summary エラー報告ステータスマスター情報
     */
    public get issueStatusesMaster(): {[key: number]: IdAndName} {
        return this._issueStatusesMaster;
    }

    /**
     * @summary 作業種別マスター情報
     */
    public get workTypeMasterList(): MWorkTypeListModel {
        return this._workTypeMasterList;
    }

    public get workTypeNameById(): (_: number) => string | null {
        return id => this._workTypeMasterList.getBy(id).name;
    }

    /**
     * @summary 対応ログステータスマスター情報
     */
    public get responseLogTroubleTypesMaster(): {[key: number]: IdAndName} {
        return {
            1: {id: 1, name: "環境依存（申告あり拠点）"},
            2: {id: 2, name: "環境依存（申告なし拠点）"},
            3: {id: 3, name: "アップデートによるエラー"},
            4: {id: 4, name: "リリース済み機能のエラー"},
            5: {id: 5, name: "その他の作業におけるエラー"},
            6: {id: 6, name: "サーバー障害（SL側）"},
            7: {id: 7, name: "サーバー障害（クラウド側）"},
            8: {id: 8, name: "サーバー障害（回線側）"},
            9: {id: 9, name: "ユーザー都合（ミス、勘違いなど）"},
            10: {id: 10, name: "操作方法（通常問い合わせ）"},
            99: {id: 99, name: "その他"}
        };
    }

    /**
     * @summary 対応ログ障害種別マスター情報
     */
    public get responseLogStatusesMaster(): {[key: number]: IdAndName} {
        return this._responseLogStatusesMaster;
    }

    /**
     * @summary 対応ログ障害レベルマスター情報
     */
    public get responseLogTroubleLevelsMaster(): {[key: number]: IdAndName} {
        return {
            0: {id: 0, name: "レベル0（自社報告：稼働に影響なし）"},
            1: {id: 1, name: "レベル1（他社報告：稼働に影響なし）"},
            2: {id: 2, name: "レベル2（稼働不可：10分以内）"},
            3: {id: 3, name: "レベル3（稼働不可：30分以内）"},
            4: {id: 4, name: "レベル4（稼働不可：1時間以内）"},
            5: {id: 5, name: "レベル5（稼働不可：1日以内）"},
            6: {id: 6, name: "レベル6（稼働負荷：1日以上）"},
            99: {id: 99, name: "その他"}
        };
    }
    // #endregion

    // #region public mutations
    /**
     * @summary 担当者マスター情報リストをセットします
     * @param mPersonList 担当者マスター情報リスト
     */
    @Mutation
    public setPersonsMaster(mPersons: {[key: number]: IdAndName}): void {
        this._mPersons = mPersons;
    }

    /**
     * @summary システムマスタ情報をセットします
     * @param systemsMaster システムマスタ情報
     */
    @Mutation
    public setSystemsMaster(systemsMaster: SystemListModel): void {
        this._systemsMaster = systemsMaster;
    }

    /**
    * @summary タスクステータスマスタ情報をセットします
    * @param systemsMaster タスクステータスマスタ情報
    */
    @Mutation
    public setTaskStatusesMaster(statuses: {[key: number]: IdAndName;}) {
        this._taskStatusesMaster = statuses;
    }

    /**
     * @summary エラー報告ステータスマスタ情報をセットします
     * @param systemsMaster タスクステータスマスタ情報
     */
    @Mutation
    public setIssueStatusesMaster(statuses: {[key: number]: IdAndName;}) {
        this._issueStatusesMaster = statuses;
    }

    /**
     * @summary 対応ログステータスマスタ情報をセットします
     * @param systemsMaster タスクステータスマスタ情報
     */
    @Mutation
    public setResponseLogStatusesMaster(statuses: {[key: number]: IdAndName;}) {
        this._responseLogStatusesMaster = statuses;
    }

    /**
     * @summary 作業種別マスター情報をセットします
     *
     * @param mWorkTypeMasterList
     */
    @Mutation
    public setWorkTypeMasterList(mWorkTypeMasterList: MWorkTypeListModel) {
        this._workTypeMasterList = mWorkTypeMasterList;
    }
    // #endregion

    /**
     * @summary 担当者マスター情報をサーバーから取り込みます
     */
    @Action
    public async fetchSystemsMaster(sheetId: number): Promise<void> {
        try {
            const response = await axios.get<System[]>("api/m_system?sheetId=" + sheetId);
            const systems: SystemListModel = new SystemListModel(response.data);
            this.setSystemsMaster(systems);
            StyleGenerator.applySystemColor(systems);
        }
        catch (ex) {
            console.error("システムマスタの取り込みに失敗しました.", ex);
        }
    }

    @Action
    public async fetchStatusesMaster(sheetId: number): Promise<boolean> {
        try {
            const response = await axios.get<Status[]>("api/m_status?sheetId=" + sheetId);
            const taskStatuses: {[index: number]: Status} = {};
            const issueStatuses: {[index: number]: Status} = {};
            const responseLogStatuses: {[index: number]: Status} = {};
            for (const status of response.data) {
                switch (status.statusType) {
                    case StatusType.Task:
                        taskStatuses[status.id] = status;
                        break;
                    case StatusType.Issue:
                        issueStatuses[status.id] = status;
                        break;
                    case StatusType.ResponseLog:
                        responseLogStatuses[status.id] = status;
                        break;
                    default: continue;
                }
            }
            this.setTaskStatusesMaster(taskStatuses);
            this.setIssueStatusesMaster(issueStatuses);
            this.setResponseLogStatusesMaster(responseLogStatuses);
            // スタイルの書き込み
            StyleGenerator.applyTaskTableRow(taskStatuses);
            StyleGenerator.applyIssueTableRow(issueStatuses);
            StyleGenerator.applyResponseLogTableRow(responseLogStatuses);
            return true;
        }
        catch {
            console.error("ステータスマスタの取り込みに失敗しました.");
        }
        return false;
    }

    /**
     * @summary 担当者マスター情報をサーバーから取り込みます
     */
    @Action
    public async fetchPersons(): Promise<void> {
        try {
            const response = await axios.get<IdAndName[]>("api/m_person");
            const personDictionary: {[index: number]: IdAndName} = {};
            for (const person of response.data) {
                personDictionary[person.id] = person;
            }
            this.setPersonsMaster(personDictionary);
        }
        catch (ex) {
            console.error(ex, "担当者マスタの取り込みに失敗しました.");
        }
    }

    /**
     * 担当者マスター情報を追加する
     *
     * @param Person
     */
    @Action
    public async addMPerson(newMPerson: IdAndName): Promise<IdAndName | undefined> {
        try {
            const response = await axios.post<IdAndName>("api/m_person", newMPerson);
            const person = response.data;
            this._mPersons[person.id] = person;
            return response.data;
        }
        catch {
            console.error("担当者マスタの追加に失敗しました.");
            return undefined;
        }
    }

    /**
     * @summary 担当者マスター情報を保存する
     * @param mPerson 保存する担当者マスター情報
     */
    @Action
    public async saveMPerson(mPerson: IdAndName): Promise<boolean> {
        try {
            const response = await axios.put<IdAndName>("api/m_person/" + mPerson.id, mPerson);
            return true;
        }
        catch {
            console.error("担当者マスタの削除に失敗しました.");
            return false;
        }
    }

    /**
     * @summary 作業種別マスター情報をサーバーから取り込みます
     */
    @Action
    public async fetchWorkTypeMaster(sheetId: number): Promise<void> {
        try {
            const response = await axios.get<MWorkType[]>("api/m_work_type?sheetId=" + sheetId);
            const dictionary: MWorkTypeListModel = new MWorkTypeListModel(response.data);
            this.setWorkTypeMasterList(dictionary);
            StyleGenerator.applyWorkTypeColor(this.workTypeMasterList);
        }
        catch (ex) {
            console.error(ex, "作業種別マスタの取り込みに失敗しました.");
        }
    }
}

export const MasterInfoStoreModule = getModule(MasterInfoStore);
