import axios from "axios";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from "../store";
import {IdAndName} from "@/models/interfaces/IdAndName";
import * as CONFIG from "@/Config";

/**
 * @summary シート情報に関するStoreを提供します.
 */
@Module({dynamic: true, store, name: "sheet", namespaced: true})
export class SheetStore extends VuexModule {
    // #region private fields
    private _sheets: IdAndName[] = [];
    private _currentSheetId?: number = (() => {
        const id = localStorage.getItem("currentSheetId");
        return id ? Number(id) : CONFIG.DEFAULT_SHEET_ID;
    })();
    // #endregion

    // #region public getters
    /**
     * @summary 現在のシートIDを取得.
     */
    public get currentSheetId(): number {
        if (this._currentSheetId) {
            return this._currentSheetId;
        }

        if (this._sheets.length > 0) {
            return this._sheets[0].id;
        }
        return CONFIG.DEFAULT_SHEET_ID;
    }

    /**
     * @summary シート一覧を取得します.
     */
    public get sheets(): IdAndName[] {
        return this._sheets;
    }
    // #endregion

    // #region public muations
    /**
     * @summary 現在のシートIDをセット.
     * @param id セットするシートID
     */
    @Mutation
    public setCurrentSheetId(id: number) {
        localStorage.setItem("currentSheetId", id.toString());
        this._currentSheetId = id;
    }

    /**
     * @summary シート一覧をセットします.
     * @param sheets セットするシート一覧
     */
    @Mutation
    public setSheets(sheets: IdAndName[]) {
        this._sheets = sheets;
    }
    // #endregion

    // # region public actions
    /**
     * @summary サーバーからシート一覧を取り込みます.
     */
    @Action
    public async fetchSheetList(): Promise<boolean> {
        try {
            const response = await axios.get<IdAndName[]>("api/sheet");
            this.setSheets(response.data);
            return true;
        }
        catch {
            console.error("シートの取り込みに失敗しました。");
        }
        return false;
    }
    // #endregon
}

export const SheetStoreModule = getModule(SheetStore);
