import {MWorkType} from "./interfaces/MWorkType";

export class MWorkTypeListModel {
    public values: MWorkType[] = [];

    /**
     * @summary コンストラクタ
     */
    public constructor(values: MWorkType[] = []) {
        Object.assign(this.values, values);
    }

    public getBy(workTypeId: number): MWorkType {
        for (const value of this.values) {
            if (value.id === workTypeId) return value;
        }

        return {id: 0, name: "", workColorLight: "", workColorDark: ""};
    }

    public getColorBy(workTypeId: number, isDark: boolean) {
        const mWorkType = this.getBy(workTypeId);
        return isDark === false ? mWorkType.workColorLight : mWorkType.workColorDark;
    }
}
