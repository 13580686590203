import {Status} from "@/models/interfaces/Status";
import {System} from "@/models/interfaces/System";
import {MWorkType} from "@/models/interfaces/MWorkType";
import {MWorkTypeListModel} from "@/models/MWorkTypeListModel";
import {SystemListModel} from "@/models/SystemListModel";

export class StyleGenerator {
    public static applyTaskTableRow(statuses: {[key: number]: Status}) {
        const style = document.createElement("style");
        document.head.appendChild(style);
        let css = "";
        for (const key in statuses) {
            const [light, dark] = statuses[key].statusColor.split(",");
            css += `.theme--light .task-row[slot-status="${statuses[key].id}"]:not(.over-limit-task) td { background: ${light} !important; }\n `;
            css += `.theme--dark .task-row[slot-status="${statuses[key].id}"]:not(.over-limit-task) td { background: ${dark} !important; }\n `;
        }
        style.innerHTML = css;
    }

    public static applyResponseLogTableRow(statuses: {[key: number]: Status}) {
        const style = document.createElement("style");
        document.head.appendChild(style);
        let css = "";
        for (const key in statuses) {
            const [light, dark] = statuses[key].statusColor.split(",");
            css += `.theme--light .response-log-row[slot-status="${statuses[key].id}"]:not(.over-limit-task) td { background: ${light} !important; }\n `;
            css += `.theme--dark .response-log-row[slot-status="${statuses[key].id}"]:not(.over-limit-task) td { background: ${dark} !important; }\n `;
        }
        style.innerHTML = css;
    }

    public static applyIssueTableRow(statuses: {[key: number]: Status}) {
        const style = document.createElement("style");
        document.head.appendChild(style);
        let css = "";
        for (const key in statuses) {
            const [light, dark] = statuses[key].statusColor.split(",");
            css += `.theme--light .issue-row[slot-status="${statuses[key].id}"]:not(.over-limit-task) td { background: ${light} !important; }\n `;
            css += `.theme--dark .task-row[slot-status="${statuses[key].id}"]:not(.over-limit-task) td { background: ${dark} !important; }\n `;
        }
        style.innerHTML = css;
    }

    public static applySystemColor(systems: SystemListModel) {
        const style = document.createElement("style");
        document.head.appendChild(style);
        let css = "";
        for (const system of systems.values) {
            css += ` .theme--light .system-col[slot-system="${system.id}"]::before { content: "●"; font-size: 1.0em; margin-right: 4px; color: ${system.systemColor}; }\n `;
            css += ` .theme--dark .system-col[slot-system="${system.id}"]::before { content: "●"; font-size: 1.0em; margin-right: 4px; color: ${system.systemColor}; }\n `;
        }
        style.innerHTML = css;
    }

    public static applyWorkTypeColor(mWorkTypeList: MWorkTypeListModel) {
        const style = document.createElement("style");
        document.head.appendChild(style);
        let css = "";
        for (const key in mWorkTypeList.values) {
            css += ` .theme--light .work-type-col[slot-work-type="${mWorkTypeList.values[key].id}"]::before { content: "●"; font-size: 1.0em; margin-right: 4px; color: ${mWorkTypeList.values[key].workColorLight}; }\n `;
            css += ` .theme--dark .work-type-col[slot-work-type="${mWorkTypeList.values[key].id}"]::before { content: "●"; font-size: 1.0em; margin-right: 4px; color: ${mWorkTypeList.values[key].workColorDark}; }\n `;
        }
        style.innerHTML = css;
    }
}
