import {ResponseLogSearchParam} from "./interfaces/ResponseLogSearchParam";

/**
 * @summary タスク検索で使用する検索パラメータを提供します
 */
export class ResponseLogSearchParamModel implements ResponseLogSearchParam {
    public statusIdArr?: number[] = undefined;
    public responseDateArr?: string[] = [];
    public sheetId?: number = 0;

    /**
     * @summary コンストラクタ
     */
    public constructor(statusIdArr?: number[], responseDateArr?: string[]) {
        this.statusIdArr = statusIdArr;
        this.responseDateArr = responseDateArr;
    }

    public getQueryString(): string {
        const query: string[] = [];

        if (this.statusIdArr && this.statusIdArr.length > 0) {
            for (const statusId of this.statusIdArr) {
                query.push("statusList[]=" + statusId);
            }
        }

        if (this.responseDateArr && this.responseDateArr[0]) {
            query.push("fromResponseDate=" + this.responseDateArr[0]);
        }

        if (this.responseDateArr && this.responseDateArr[1]) {
            query.push("endResponseDate=" + this.responseDateArr[1]);
        }

        if (this.sheetId) {
            query.push("sheetId=" + this.sheetId);
        }

        return "?" + query.join("&");
    }
}
