import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdi"
    },
    theme: {
        themes: {
            dark: {
                primary: colors.blue.lighten1,
                secondary: colors.pink.base,
                accent: colors.deepOrange.base,
                error: colors.red.base,
                info: colors.grey.darken3,
                success: colors.lightBlue.base,
                warning: colors.yellow.base
            },
            light: {
                primary: colors.blue.lighten1,
                secondary: colors.pink.base,
                accent: colors.deepOrange.base,
                error: colors.red.base,
                info: colors.grey.lighten4,
                success: colors.lightGreen.base,
                warning: colors.yellow.base
            }
        }
    }
});
