import axios from "axios";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from "../store";
import {AuthConfig, URL} from "@/Config";

/**
 * @summary エラー報告情報に関するStoreを提供します.
 */
@Module({dynamic: true, store, name: "auth", namespaced: true})
export class AuthStore extends VuexModule {
    // #region private filelds

    // #endregion

    // #region public getters
    public get token(): string {
        return AuthConfig.token;
    }

    public get refreshToken(): string {
        return AuthConfig.refreshToken;
    }

    public get expiresIn(): Date {
        return AuthConfig.expiresIn;
    }

    public get memberName(): string {
        return AuthConfig.memberName;
    }

    public get memberId(): number {
        return AuthConfig.memberId;
    }

    public get loginId(): string {
        return AuthConfig.loginId;
    }

    public get isLogin(): boolean {
        return AuthConfig.isLogin;
    }

    public get groupIds(): number[] {
        return AuthConfig.groupIds;
    }
    // #endregion

    // #region mutaions
    @Mutation
    public setRefreshToken(refreshToken: string) {
        localStorage.setItem("refreshToken", refreshToken);
        AuthConfig.refreshToken = refreshToken;
    }

    @Mutation
    public setToken(token: string) {
        localStorage.setItem("token", token);
        AuthConfig.token = token;
    }

    @Mutation
    public setExpiresIn(date: string) {
        localStorage.setItem("expiresIn", date);
        AuthConfig.expiresIn = new Date(date);
    }

    @Mutation
    public setMemberName(name: string) {
        localStorage.setItem("memberName", name);
        AuthConfig.memberName = name;
    }

    @Mutation
    public setMemberId(id: number) {
        localStorage.setItem("memberId", id.toString());
        AuthConfig.memberId = id;
    }

    @Mutation
    public setLoginId(id: string) {
        localStorage.setItem("loginId", id);
        AuthConfig.loginId = id;
    }

    @Mutation
    public setIsLogin(isLogin: boolean) {
        localStorage.setItem("isLogin", isLogin ? "true" : "");
        AuthConfig.isLogin = isLogin;
    }

    @Mutation
    public setGroupIds(ids: number[]) {
        localStorage.setItem("groupIds", JSON.stringify(ids));
        AuthConfig.groupIds = ids;
    }
    // #endregion

    // #region actions
    @Action
    public getIsAuthenticated(): boolean {
        if (this.isLogin && new Date() < this.expiresIn) {
            return true;
        }
        return false;
    }

    @Action
    public async tryRefreshToken(): Promise<boolean> {
        try {
            this.setToken(this.refreshToken);
            const response = await axios.put("api/auth");
            if (response.data.code === "0000") {
                const data = response.data.data;
                this.storeUserInfo(data);
                return true;
            }
        }
        catch (ex) {
            console.log("トークンの再交付に失敗しました。", ex);
        }
        return false;
    }

    @Action
    public logout() {
        this.setRefreshToken("");
        this.setToken("");
        this.setExpiresIn("");
        this.setMemberId(0);
        this.setMemberName("");
        this.setLoginId("");
        this.setIsLogin(false);
    }

    @Action
    public async validateToken(): Promise<boolean> {
        try {
            const response = await axios.get("api/auth");
            if (response.data.code === "0000") {
                return true;
            }
        }
        catch {
            console.log("トークンが無効です.");
        }
        return false;
    }

    /**
     * ログイン情報を取得しログインします.
     * @param body ログイン情報
     */
    @Action
    public async login(body: {[key: string]: string}): Promise<boolean> {
        try {
            const response = await axios.post("api/auth", {
                loginId: body.loginId,
                password: body.password
            });
            if (response.data.code === "0000") {
                const data = response.data.data;
                this.storeUserInfo(data);
                return true;
            }
        }
        catch (ex) {
            console.log("ログインに失敗しました。", ex);
        }
        return false;
    }

    /**
     * @summary ログイン情報をセットしローカルストレージへ保存します.
     * @param data ログイン情報
     */
    @Action
    public storeUserInfo(data: any) {
        const token = data.accessToken as string | undefined;
        if (token) {
            this.setToken(token);
        }

        const refreshToken = data.refreshToken as string | undefined;
        if (refreshToken) {
            this.setRefreshToken(refreshToken);
        }

        const expiresIn = data.expiresIn as string | undefined;
        if (expiresIn) {
            this.setExpiresIn(expiresIn);
        }

        const memberId = Number(data.memberId ? data.memberId : 0);
        if (memberId) {
            this.setMemberId(memberId);
        }

        const memberName = data.memberName as string | undefined;
        if (memberName) {
            this.setMemberName(memberName);
        }

        const loginId = data.loginId as string | undefined;
        if (loginId) {
            this.setLoginId(loginId);
        }

        const groupIds = data.groupIds as number[] | undefined;
        if (groupIds) {
            this.setGroupIds(groupIds);
        }

        this.setIsLogin(true);
    }
    // ##endregion
}

export const AuthStoreModule = getModule(AuthStore);
