import Vue from "vue";
import App from "./App.vue";
import "./main.scss";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import "@/core/axios.js";

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App)
}).$mount("#app");
