import {UpdateLogSearchParam} from "./interfaces/UpdateLogSearchParam";

/**
 * @summary アップデート履歴検索で使用する検索パラメータを提供します
 */
export class UpdateLogSearchParamModel implements UpdateLogSearchParam {
    public updateDateArr: string[] = [];
    public sheetId?: number = 0;

    /**
     * @summary コンストラクタ
     */
    public constructor(updateDateArr: string[]) {
        this.updateDateArr = updateDateArr;
    }

    public getQueryString(): string {
        const query: string[] = [];

        if (this.updateDateArr && this.updateDateArr[0]) {
            query.push("fromUpdateDate=" + this.updateDateArr[0]);
        }

        if (this.updateDateArr && this.updateDateArr[1]) {
            query.push("endUpdateDate=" + this.updateDateArr[1]);
        }

        if (this.sheetId !== undefined && this.sheetId !== null) {
            query.push("sheetId=" + this.sheetId);
        }

        return "?" + query.join("&");
    }
}
